// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import 'nodelist-foreach-polyfill';
import Vue from 'vue';
import App from './App';
import router from './router';
import routes from '@/routes';
import store from './store';
import VeeValidate from 'vee-validate';
import MaskedInput from 'vue-text-mask';

import 'bootstrap/dist/css/bootstrap.min.css';
// import 'bootstrap';
import 'font-awesome/css/font-awesome.min.css';
import '@/assets/css/main.css';
import '@fengyuanchen/datepicker/dist/datepicker.min.css';
import '@fengyuanchen/datepicker/dist/datepicker.esm.js';
import 'bs-stepper/dist/css/bs-stepper.min.css';
import globalMixins from '@/mixins/global';

Vue.config.productionTip = false;

const veeValidateConfig = {
    'errorBagName': 'validationErrors'
};

Vue.use(VeeValidate, veeValidateConfig);

Vue.component('masked-input', MaskedInput);

Vue.mixin(globalMixins);

try {
    window.Popper = require('popper.js').default;
    window.$ = window.jQuery = require('jquery');
    // import bootstrap
    require('bootstrap');
} catch (e) {}

/* eslint-disable no-new */
/* global $ */
new Vue({
    'el': '#app',
    router,
    store,
    'template': '<App/>',
    'components': { App },
    'render': h => h(App),
    created () {
        // (function (d, s, id) {
        //     var js = d.getElementsByTagName(s)[0];
        //     var fjs = d.getElementsByTagName(s)[0];

        //     if (d.getElementById(id)) {
        //         return;
        //     }

        //     js = d.createElement(s);
        //     js.id = id;
        //     js.src = 'https://connect.facebook.net/en_US/sdk.js';
        //     fjs.parentNode.insertBefore(js, fjs);
        // }(document, 'script', 'facebook-jssdk'));

        // let datatableImport = document.createElement('script');
        // datatableImport.setAttribute('src', '//cdn.datatables.net/1.10.16/js/jquery.dataTables.min.js');
        // datatableImport.onload = function () {
        //     console.log('jquery.dataTables onload');

        //     let datatableBsImport = document.createElement('script');
        //     datatableBsImport.setAttribute('src', '//cdn.datatables.net/1.10.16/js/dataTables.bootstrap.min.js');
        //     datatableBsImport.onload = function () {
        //         console.log('dataTables.bootstrap onload');

        //         let additionalScripts = ['https://cdn.datatables.net/buttons/1.5.2/js/dataTables.buttons.js',
        //             'https://cdn.datatables.net/buttons/1.5.2/js/buttons.print.min.js',
        //             'https://cdnjs.cloudflare.com/ajax/libs/jszip/3.1.3/jszip.js',
        //             'https://cdn.datatables.net/buttons/1.5.2/js/buttons.html5.js'];

        //         for (let i = 0; i < additionalScripts.length; i++) {
        //             let newScriptNode = document.createElement('script');
        //             newScriptNode.setAttribute('src', additionalScripts[i]);
        //             document.head.appendChild(newScriptNode);
        //         }
        //     };

        //     document.head.appendChild(datatableBsImport);
        // };

        // document.head.appendChild(datatableImport);

        this.setupMenuList();

        $('body').on('hidden.bs.modal', '.modal', function (e) {
            if ($('.modal').hasClass('show')) {
                $('body').addClass('modal-open');
            }
        });
    },
    'methods': {
        setupMenuList () {
            this.$store.commit('setMenuList', routes[0].children);
        }
    }
});
