var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"main-layout"},[_c('side-menu',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.handleMenuClickOutside),expression:"handleMenuClickOutside"}],model:{value:(_vm.sideMenuOpen),callback:function ($$v) {_vm.sideMenuOpen=$$v},expression:"sideMenuOpen"}}),_c('div',{staticClass:"page-wrapper",class:{
            'side-menu-open': _vm.sideMenuOpen,
            'admin-view': _vm.isAdmin
        }},[_c('div',{staticClass:"content-wrapper"},[_c('nav',{staticClass:"top-navigation"},[_c('button',{staticClass:"btn btn-menu side-menu-toggle",on:{"click":_vm.toggleSideMenu}},[_c('div',{staticClass:"btn-menu-icon",style:({
                            'background-image': ("url(" + _vm.menuBurgerIcon + ")")
                        })}),_c('div',{staticClass:"btn-menu-text"},[_vm._v("Menu")])]),_c('div',{staticClass:"nav-spacer"}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isPublic),expression:"isPublic"}],staticClass:"nav-login-signup registration-link"},[_c('router-link',{attrs:{"to":{
                        'name': 'register'
                    }}},[_vm._v(" Register New Account ")]),_c('router-link',{attrs:{"to":{
                        'name': 'login'
                    }}},[_vm._v(" Login ")])],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isPublic),expression:"!isPublic"}],staticClass:"dropdown"},[_c('button',{staticClass:"btn btn-dropdown-menu dropdown-toggle",attrs:{"type":"button","id":"dropdownUser","data-bs-toggle":"dropdown","aria-haspopup":"true","aria-expanded":"false"}},[_vm._v(" "+_vm._s(_vm.userNameOrPasswordDisplay)+" ")]),_c('div',{staticClass:"dropdown-menu",attrs:{"aria-labelledby":"dropdownUser"}},[_c('router-link',{staticClass:"dropdown-item",attrs:{"to":{
                            'name': 'change-password'
                        }}},[_vm._v(" Change Password ")])],1)]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isPublic),expression:"!isPublic"}]},[_c('a',{staticClass:"btn btn-secondary btn-logout",attrs:{"href":"javascript: void(0);"},on:{"click":_vm.logoutUser}},[_vm._v(" Logout ")])])]),_c('div',{staticClass:"page-content-wrapper"},[_c('div',{staticClass:"page-container"},[(_vm.pageNoDefaultContainer)?_c('div',[_c('router-view')],1):_c('div',{staticClass:"page-content"},[_c('router-view')],1)]),_c('div',{staticClass:"text-center"},[_c('small',[_vm._v("© "+_vm._s(new Date().getFullYear())+" Insure Savvy Sdn Bhd (1244396-D). All Rights Reserved."),_c('br'),_c('a',{attrs:{"target":"_blank","href":"http://www.insuresavvy.com.my/privacy-policy/"}},[_vm._v("Privacy Policy")])])])])])]),_c('div',{staticClass:"modal fade",attrs:{"id":"unauthorizedModal","tabindex":"-1","role":"dialog","data-backdrop":"static","aria-labelledby":"unauthorizedModal","aria-hidden":"true"}},[_c('div',{staticClass:"modal-dialog",attrs:{"role":"document"}},[_c('div',{staticClass:"modal-content"},[_vm._m(0),_c('div',{staticClass:"modal-body"},[_vm._v(" Please login to continue. ")]),_c('div',{staticClass:"modal-footer"},[_c('button',{staticClass:"btn btn-primary",attrs:{"type":"button"},on:{"click":_vm.redirectToLogin}},[_vm._v("Login")])])])])])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"modal-header"},[_c('h5',{staticClass:"modal-title"},[_vm._v("Requires user login.")])])}]

export { render, staticRenderFns }