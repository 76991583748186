<template>
    <div class="main-layout">
        <side-menu v-model="sideMenuOpen"
            v-click-outside="handleMenuClickOutside">
        </side-menu>
        <div class="page-wrapper"
            :class="{
                'side-menu-open': sideMenuOpen,
                'admin-view': isAdmin
            }">
            <div class="content-wrapper">
                <nav class="top-navigation">
                    <button class="btn btn-menu side-menu-toggle"
                        @click="toggleSideMenu">
                        <div class="btn-menu-icon"
                            :style="{
                                'background-image': `url(${menuBurgerIcon})`
                            }">
                        </div>
                        <div class="btn-menu-text">Menu</div>
                    </button>
                    <div class="nav-spacer">
                    </div>
                    <div v-show="isPublic" class="nav-login-signup registration-link">
                        <router-link :to="{
                            'name': 'register'
                        }">
                            Register New Account
                        </router-link>

                        <router-link :to="{
                            'name': 'login'
                        }">
                            Login
                        </router-link>
                    </div>
                    <div v-show="!isPublic" class="dropdown">
                        <button class="btn btn-dropdown-menu dropdown-toggle"
                            type="button"
                            id="dropdownUser"
                            data-bs-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false">
                            {{userNameOrPasswordDisplay}}
                        </button>
                        <div class="dropdown-menu" aria-labelledby="dropdownUser">
                            <!-- <a class="dropdown-item" href="#">Action</a>
                            <a class="dropdown-item" href="#">Another action</a> -->
                            <router-link :to="{
                                'name': 'change-password'
                            }"
                                class="dropdown-item">
                                Change Password
                            </router-link>
                        </div>
                    </div>
                    <div v-show="!isPublic">
                        <a href="javascript: void(0);"
                            class="btn btn-secondary btn-logout"
                            @click="logoutUser">
                            Logout
                        </a>
                    </div>
                </nav>

                <div class="page-content-wrapper">
                    <!-- <div class="page-title">{{pageTitle}}</div> -->
                    <div class="page-container">
                        <div v-if="pageNoDefaultContainer">
                            <router-view/>
                        </div>
                        <div v-else class="page-content">
                            <!-- <div class="mb-4 text-center">
                                <img src="/static/campaigns/boost_ipay88.png" class="img-fluid">
                            </div> -->
                            <router-view/>
                        </div>
                    </div>
                    <div class="text-center">
                        <small>&copy; {{ new Date().getFullYear() }} Insure Savvy Sdn Bhd (1244396-D). All Rights Reserved.<br>
                        <a target="_blank" href="http://www.insuresavvy.com.my/privacy-policy/">Privacy Policy</a></small>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal fade" id="unauthorizedModal" tabindex="-1" role="dialog" data-backdrop="static" aria-labelledby="unauthorizedModal" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Requires user login.</h5>
                </div>
                <div class="modal-body">
                    Please login to continue.
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-primary" @click="redirectToLogin">Login</button>
                </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
/* global $ */
// import moment from 'moment';
import ClickOutside from 'vue-click-outside';
import viewport from 'viewport-dimensions';
import menuBurgerSvg from '@/assets/img/icons/menu_burger.svg';
import SideMenu from './side-menu';
export default {
    'name': 'Layout',
    'components': {
        SideMenu
    },
    'directives': {
        ClickOutside
    },
    'computed': {
        menuBurgerIcon () {
            return menuBurgerSvg;
        },
        pageTitle () {
            return this.$store.state.app.pageTitle;
        },
        userNameOrPasswordDisplay () {
            return this.$store.state.user.userName ? this.$store.state.user.userName : this.$store.state.user.email;
        },
        isPublic () {
            return this.$store.state.isPublic;
        },
        sideMenuList () {
            return this.$store.state.app.sideMenuList;
        },
        pageNoDefaultContainer () {
            return this.$route.meta ? this.$route.meta.noDefaultContainer ? this.$route.meta.noDefaultContainer : false : false;
        },
        isAdmin () {
            return this.$store.state.user.userRole === 1;
        }
    },
    'watch':
    {
        pageWidth () {
            this.detectIsMobile();
        },
        isMobile (val) {
            this.handleSideMenuDefault(val);
        },
        sideMenuList () {
            this.goToDefaultPath();
        },
        $route (val) {
            if (val.path === '/') {
                this.goToDefaultPath();
            }

            // closes side menu if it's mobile mode
            if (this.isMobile) {
                this.sideMenuOpen = false;
            }
        },
        isPublic (isPublic) {
            if (!isPublic) {
                document.body.classList.add('authenticated');
            } else {
                document.body.classList.remove('authenticated');
            }
        },
        isMobileBotShown (val) {
            if (val) {
                if (this.checkMobileBotInterval) {
                    clearInterval(this.checkMobileBotInterval);
                }

                let needToShowPopover = true;

                // if (this.localStorageTest()) {
                //     let lastShownPopover = localStorage.getItem('lastPopoverShown');

                //     if (lastShownPopover) {
                //         let lastDate = Date.parse(lastShownPopover);

                //         if (lastDate) {
                //             if (moment(lastDate).isBefore(moment().subtract(1, 'months'))) {
                //                 // need to show
                //             } else {
                //                 needToShowPopover = false;
                //             }
                //         }
                //     }
                // }

                if (needToShowPopover) {
                    localStorage.setItem('lastPopoverShown', new Date());
                    $('#openVSButton').popover({
                        'html': true,
                        'content': 'You can use this intelligent tool to find panel workshops, hospitals, police stations, tips to avoid tow truck scams, claim submission etc. Try it!',
                        'title': 'Chatbot <button class="btn-popover-dismiss close" onclick="let popover = this.closest(\'.popover\');if (popover) {document.body.removeChild(popover);}"></button>',
                        'placement': 'top'
                    });

                    $('#openVSButton').popover('show');

                    setTimeout(function () {
                        $('#openVSButton').popover('hide');
                    }, 3000);
                }
            }
        }
    },
    data () {
        return {
            'sideMenuOpen': true,
            'pageWidth': 0,
            'isMobile': false,
            'checkMobileBotInterval': null,
            'isMobileBotShown': false
        };
    },
    mounted () {
        this.detectWidthChange();
        this.detectIsMobile();
        this.handleSideMenuDefault(this.isMobile);
        window.addEventListener('resize', this.detectWidthChange);
        window.addEventListener('orientationchange', this.detectWidthChange);

        this.getLoginStatus();

        this.checkMobileBotInterval = setInterval(() => {
            let botButton = document.querySelector('#openVSButton');
            if (botButton && !this.isPublic) {
                this.isMobileBotShown = true;
            }
        }, 1000);
    },
    destroyed () {
        window.removeEventListener('resize', this.detectWidthChange);
        window.removeEventListener('orientationchange', this.detectWidthChange);
    },
    'methods': {
        detectWidthChange () {
            this.pageWidth = viewport.width();
        },
        detectIsMobile () {
            if (this.pageWidth < 992) {
                this.isMobile = true;
            } else {
                this.isMobile = false;
            }

            this.$store.commit('setIsMobile', this.isMobile);
        },
        handleSideMenuDefault (isMobile) {
            if (isMobile) {
                this.sideMenuOpen = false;
            } else {
                if (!this.sideMenuOpen) {
                    this.sideMenuOpen = true;
                }
            }
        },
        toggleSideMenu () {
            this.sideMenuOpen = !this.sideMenuOpen;
        },
        handleMenuClickOutside (event) {
            let menuToggleButton = document.querySelector('.side-menu-toggle');
            let menuToggleNodes = [];
            menuToggleNodes.push(menuToggleButton);

            menuToggleButton.childNodes.forEach((node) => {
                menuToggleNodes.push(node);
            });

            if (!(menuToggleNodes.some((dom) => dom === event.target)) && this.isMobile && this.sideMenuOpen) {
                this.sideMenuOpen = false;
            }
        },
        getLoginStatus () {
            this.axiosInstance.get('account/GetAccountState')
                .then((res) => {
                    // set isPublic = false
                    this.$store.commit('setAppStatus', false);
                    this.$store.commit('setUserDetails', res.data);
                })
                .catch((error) => {
                    if (error.response && error.response.status === 401) {
                        // set isPublic = false
                        this.$store.commit('setAppStatus', true);
                    } else {
                        console.log(error);
                    }
                });
        },
        logoutUser () {
            this.axiosInstance.get('account/logout')
                .finally(() => {
                    this.$store.commit('setAppStatus', true);
                    this.$store.commit('clearUserState');
                    this.$router.push({
                        'name': 'login'
                    });
                });
        },
        redirectToLogin () {
            this.axiosInstance.get('account/logout')
                .finally(() => {
                    this.$store.commit('setAppStatus', true);
                    this.$store.commit('clearUserState');

                    let queryReturn = {};

                    if (this.$route.name !== 'login') {
                        queryReturn.from = window.location.href;

                        this.$router.push({
                            'name': 'login',
                            'query': queryReturn
                        });
                    } else {
                        $('#unauthorizedModal').modal('hide');
                    }
                });
        },
        goToDefaultPath () {
            if (this.$route.path === '/' && this.sideMenuList.length > 0) {
                if (this.isPublic) {
                    this.$router.push({
                        'name': 'login'
                    });
                } else {
                    this.$router.push({
                        'name': this.sideMenuList[0].route
                    });
                }
            }
        },
        isMyScriptLoaded (url) {
            let scripts = document.getElementsByTagName('script');
            for (let i = scripts.length; i--;) {
                if (scripts[i].src === url) return true;
            }
            return false;
        },
        localStorageTest () {
            let test = 'test';
            try {
                localStorage.setItem(test, test);
                localStorage.removeItem(test);
                return true;
            } catch (e) {
                return false;
            }
        }
    }
};
</script>

<style>
:root
{
    --top-nav-height: 64px;
    --side-menu-width: 250px;
}

.main-layout
{
    width: 100%;
    overflow-x: hidden;
    min-height: 100vh;
}

.main-layout .top-navigation
{
    position: fixed;
    left: 0px;
    right: 0;
    display: flex;
    height: 64px;
    align-items: center;
    color: #272a30;
    background-color: #fff;
    box-shadow: 0 2px 4px -1px rgba(0,0,0,.2), 0 4px 5px 0 rgba(0,0,0,.14), 0 1px 10px 0 rgba(0,0,0,.12);
    transition: 0.2s left ease-in-out;
    z-index: 1;
}

@media (max-width: 575px)
{
    .main-layout .top-navigation
    {
        width: 100vw;
    }
}

.main-layout .top-navigation .btn-menu
{
    display: flex;
    align-items: center;
    padding: 6px 8px;
    margin: 2px 5px 0 5px;
    background-color: transparent;
    transition: 0.2s opacity ease-in-out;
}

.main-layout .top-navigation .btn-menu:active
{
    opacity: .5;
}

.main-layout .top-navigation .btn-menu:focus
{
    box-shadow: none;
}

.main-layout .top-navigation .btn-menu > .btn-menu-icon
{
    width: 24px;
    height: 24px;
    background-size: 24px 24px;
    background-position: top left;
    background-repeat: no-repeat;
}

.main-layout .top-navigation .btn-menu > .btn-menu-text
{
    margin-left: 10px;
}

@media(min-width: 992px)
{
    .main-layout .top-navigation .btn-menu
    {
        display: none;
    }
}

@media(min-width: 576px) {
    .main-layout .top-navigation .btn-menu
    {
        padding: 6px 8px;
        margin: 2px 10px 0 10px;
    }
}

.main-layout .top-navigation .nav-spacer
{
    flex: 1;
}

.main-layout .top-navigation .btn-dropdown-menu
{
    height: 64px;
    border-radius: 0;
    margin-right: 10px;
    background-color: transparent;
    font-size: 1rem;
}

@media (max-width: 370px)
{
    .main-layout .top-navigation .btn-dropdown-menu
    {
        max-width: 130px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin-right: 5px;
        padding: 5px 5px;
    }
}

@media (max-width: 575px)
{
    .main-layout .top-navigation .btn-dropdown-menu
    {
        font-size: .8rem;
    }

    .main-layout .top-navigation .btn-menu > .btn-menu-text
    {
        font-size: .8rem;
    }
}

.main-layout .top-navigation .btn-dropdown-menu:focus
{
    box-shadow: none;
}

.main-layout .top-navigation .btn-logout
{
    margin-right: 20px;
}

.main-layout .top-navigation .nav-login-signup
{
    margin-right: 20px;
}

.main-layout .top-navigation .nav-login-signup a:not(:last-child)
{
    margin-right: 15px;
}

.main-layout > .page-wrapper
{
    width: 100%;
    overflow-x: hidden;
    min-height: 100vh;
    background-color: #f3f3f4;
    padding-left: 0;
    transition: 0.2s padding ease-in-out;
}

.main-layout > .page-wrapper.side-menu-open
{
    padding-left: 250px;
}

.main-layout > .page-wrapper.side-menu-open .top-navigation
{
    left: 250px;
}

.main-layout > .page-wrapper .content-wrapper
{
    position: relative;
    background-color: #f3f3f4;
    min-height: 100vh;
}

.main-layout > .page-wrapper .content-wrapper > .page-content-wrapper
{
    padding-top: 64px;
}

@media (max-width: 575px)
{
    .main-layout > .page-wrapper .content-wrapper > .page-content-wrapper
    {
        width: 100vw;
    }
}

.page-title
{
    height: 80px;
    line-height: 80px;
    font-weight: 300;
    font-size: 1.5rem;
    padding: 0 20px;
    background-color: #fff;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.page-container
{
    padding: 1rem;
}

.page-content
{
    padding: 1rem;
    width: 100%;
    max-width: 1140px;
    margin: 0 auto;
    background-color: #fff;
    transition: 0.2s all ease-in-out;
}

@media (min-width: 576px)
{
    .page-content
    {
        padding: 3rem;
    }
}

.admin-view .page-content {
    padding: 1rem !important;
    max-width: none;
}

.admin-view table {
    font-size: 13px;
}

.btn-popover-dismiss {
    position: absolute;
    right: 0;
    top: 0;
    height: 36px;
    width: 36px;
    font-size: 1.5rem;
}
</style>
